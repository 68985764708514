import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/codebuild/output/src1753048302/src/storefront/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/codebuild/output/src1753048302/src/storefront/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/codebuild/output/src1753048302/src/storefront/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/codebuild/output/src1753048302/src/storefront/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/codebuild/output/src1753048302/src/storefront/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/codebuild/output/src1753048302/src/storefront/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/codebuild/output/src1753048302/src/storefront/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/codebuild/output/src1753048302/src/storefront/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/codebuild/output/src1753048302/src/storefront/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/codebuild/output/src1753048302/src/storefront/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_6YCPEsU79e9ERhHq5su2zFcu48maKnh46pu3Nz_pG5c from "/codebuild/output/src1753048302/src/storefront/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk from "/codebuild/output/src1753048302/src/storefront/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/codebuild/output/src1753048302/src/storefront/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/codebuild/output/src1753048302/src/storefront/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/codebuild/output/src1753048302/src/storefront/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_VW0S5L6gti_L2lJP5Fh8KfD6_3GK52P9spdjsc0v3y4 from "/codebuild/output/src1753048302/src/storefront/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import amplifyConfig_1O2LchJ7EHF_RU6gl99KUS7M5KrQRvbzvkrqGgeBw84 from "/codebuild/output/src1753048302/src/storefront/plugins/amplifyConfig.ts";
import analytics_client_Raz2I9RFxTPX2jC0fpwrwSbCfeCUv9y47ECdvh7yt2w from "/codebuild/output/src1753048302/src/storefront/plugins/analytics.client.js";
import authHub_L1gZbzFqo_M5xSbSwE_eiDW3oWFxyMb1EBO7E9kIr_0 from "/codebuild/output/src1753048302/src/storefront/plugins/authHub.js";
import chunk_reload_client_MBOG2rXU4zuDCkXhVjHGK1O6_nKS7G9phAcFGCJ0jME from "/codebuild/output/src1753048302/src/storefront/plugins/chunk-reload.client.ts";
import error_handler__pSwgjkjHyQz48eWe45OxLaABCs4sek7eLtYHu9yC1k from "/codebuild/output/src1753048302/src/storefront/plugins/error-handler.js";
import fontawesome_JnKv9qFGGymEa8jchv4AtGKiPqz5KDyuOewtt9OIRng from "/codebuild/output/src1753048302/src/storefront/plugins/fontawesome.js";
import gtm_client_FmyIzOQfnL7kfwKGOuBFBaPlhm1W5ajGN2WqAk_C3eg from "/codebuild/output/src1753048302/src/storefront/plugins/gtm.client.js";
import localStorageWatcher_client_lA6vchD_93TF_wAzN7r3CGY41bk0JbedSrwRv6V5lxQ from "/codebuild/output/src1753048302/src/storefront/plugins/localStorageWatcher.client.js";
import piniaSubscribe_KQraf_o_dZdsebhAz7_cz6yfxnsjlXkRuB2N1PR7xXw from "/codebuild/output/src1753048302/src/storefront/plugins/piniaSubscribe.js";
import router_fix_Os3BpD2hCx_x1M50uw22058fp_bhgXRq9dzHKPPxoZ8 from "/codebuild/output/src1753048302/src/storefront/plugins/router.fix.ts";
import sw_deregister_client_xc8pUm_bnUjQWgGbnMWRcXi_tnjs3QIMLtx72d5BCa8 from "/codebuild/output/src1753048302/src/storefront/plugins/sw-deregister.client.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_client_6YCPEsU79e9ERhHq5su2zFcu48maKnh46pu3Nz_pG5c,
  plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  plugin_VW0S5L6gti_L2lJP5Fh8KfD6_3GK52P9spdjsc0v3y4,
  amplifyConfig_1O2LchJ7EHF_RU6gl99KUS7M5KrQRvbzvkrqGgeBw84,
  analytics_client_Raz2I9RFxTPX2jC0fpwrwSbCfeCUv9y47ECdvh7yt2w,
  authHub_L1gZbzFqo_M5xSbSwE_eiDW3oWFxyMb1EBO7E9kIr_0,
  chunk_reload_client_MBOG2rXU4zuDCkXhVjHGK1O6_nKS7G9phAcFGCJ0jME,
  error_handler__pSwgjkjHyQz48eWe45OxLaABCs4sek7eLtYHu9yC1k,
  fontawesome_JnKv9qFGGymEa8jchv4AtGKiPqz5KDyuOewtt9OIRng,
  gtm_client_FmyIzOQfnL7kfwKGOuBFBaPlhm1W5ajGN2WqAk_C3eg,
  localStorageWatcher_client_lA6vchD_93TF_wAzN7r3CGY41bk0JbedSrwRv6V5lxQ,
  piniaSubscribe_KQraf_o_dZdsebhAz7_cz6yfxnsjlXkRuB2N1PR7xXw,
  router_fix_Os3BpD2hCx_x1M50uw22058fp_bhgXRq9dzHKPPxoZ8,
  sw_deregister_client_xc8pUm_bnUjQWgGbnMWRcXi_tnjs3QIMLtx72d5BCa8
]