
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexxFIO8WrqDzYxzmdVSY6oauUkc9jstFMxDjHx8wrlsZUMeta } from "/codebuild/output/src1753048302/src/storefront/pages/index.vue?macro=true";
import { default as loginfRUKSfwpVRzr12wHl5KMD99TEXR_458eS4EiKYm25QnDYMeta } from "/codebuild/output/src1753048302/src/storefront/pages/login.vue?macro=true";
import { default as cookieGMNq2qdseIdILZp4WT8OmqbPGYiNFXeZEYV7_6itlk8Meta } from "/codebuild/output/src1753048302/src/storefront/pages/cookie.vue?macro=true";
import { default as searchYqm3rQbJgQHwzLtL_45qwnSJvQjs3MQy8HCJYO_45lLlKNoMeta } from "/codebuild/output/src1753048302/src/storefront/pages/search.vue?macro=true";
import { default as my_45cartAeIUQSBmbBKub4haVtkbVOIf5ols_45jkne3mdr_45N_45vmwMeta } from "/codebuild/output/src1753048302/src/storefront/pages/my-cart.vue?macro=true";
import { default as on_45saleUijq11pp7iLAtEUmTE8WZ28g7QY6IxhIViGb3MzmZTMMeta } from "/codebuild/output/src1753048302/src/storefront/pages/on-sale.vue?macro=true";
import { default as about_45us1SbKFkDyjxYhoCJ1Q9moxY2vJeWamMIrz4NCpUi_45yGMMeta } from "/codebuild/output/src1753048302/src/storefront/pages/about-us.vue?macro=true";
import { default as book_45clubrKovlaaqEhiVg_45lEMxBfn6sP1HFdQkWM_45ADRn_yoqMUMeta } from "/codebuild/output/src1753048302/src/storefront/pages/book-club.vue?macro=true";
import { default as contact_45usUaUFqc4JCAy_45eMkRQJD3e9O_GTqJq4SbmLtTsg582IIMeta } from "/codebuild/output/src1753048302/src/storefront/pages/contact-us.vue?macro=true";
import { default as newspapersFn7jW78i60lMNA4gN1oTCqdamC4sjffA4VfGVb5zVnYMeta } from "/codebuild/output/src1753048302/src/storefront/pages/newspapers.vue?macro=true";
import { default as _91slug_93EhScuFPBIFOiFCU_FYmdXx2X_45iBoFGvLXHn2ykPARG0Meta } from "/codebuild/output/src1753048302/src/storefront/pages/book/[slug].vue?macro=true";
import { default as indexDGpGBe5P2tg51O93g0o8S_6OKP2vUOMqufA5U2BiPnkMeta } from "/codebuild/output/src1753048302/src/storefront/pages/books/index.vue?macro=true";
import { default as barion_45start3Xedf_45X9Krrs38t3NKQDJPq6XyTYffBPil8bYx5KCvMMeta } from "/codebuild/output/src1753048302/src/storefront/pages/barion-start.vue?macro=true";
import { default as paypal_45startv9xcHFUyksvfKOVdoVt3_45VDfCw5C21AqwHeONrTPhbwMeta } from "/codebuild/output/src1753048302/src/storefront/pages/paypal-start.vue?macro=true";
import { default as _91_46_46_46catchAll_93qkJIqgNPrpX8Zvss1QfqIhesCk6mA5OeD9qjFcKfhoAMeta } from "/codebuild/output/src1753048302/src/storefront/pages/[...catchAll].vue?macro=true";
import { default as indexTIC8vvB8wG_45y2bVksPzQb_45IRlalEP_45Twul_ujdLILZUMeta } from "/codebuild/output/src1753048302/src/storefront/pages/checkout/index.vue?macro=true";
import { default as payment_45resultSJHRm9aNVuTnLy_45XjDixYXuOsHB9saV3JDseGX_45TX88Meta } from "/codebuild/output/src1753048302/src/storefront/pages/payment-result.vue?macro=true";
import { default as privacy_45policyPcP3BzzC9rtgN_TVXetYlvkRv8EzqIFy6kgWYEovEmEMeta } from "/codebuild/output/src1753048302/src/storefront/pages/privacy-policy.vue?macro=true";
import { default as indexM9Na2t96_6nDNQiL1Zfz_45_3676r4OGzycfeHMl6HgBMMeta } from "/codebuild/output/src1753048302/src/storefront/pages/my-orders/index.vue?macro=true";
import { default as newly_45publishedC7dvKtlqL8N_KjQvkpXlUuxjBvVaDBDPKhgGahm8VVQMeta } from "/codebuild/output/src1753048302/src/storefront/pages/newly-published.vue?macro=true";
import { default as _91slug_93FtFigMQH7JucTN92f8OIyNTvNBLlWvcS_45aaXg19GfGEMeta } from "/codebuild/output/src1753048302/src/storefront/pages/my-orders/[slug].vue?macro=true";
import { default as thankyouuvN0uL8W1XrI3trcgTGSX178nc7qGyvxlBzlO9wMA_QMeta } from "/codebuild/output/src1753048302/src/storefront/pages/checkout/thankyou.vue?macro=true";
import { default as upcoming_45releasessjuxHoNjOFtERtl045ClT1jAh8zM_45jeXGWA_45CA3dJ9cMeta } from "/codebuild/output/src1753048302/src/storefront/pages/upcoming-releases.vue?macro=true";
import { default as terms_45and_45conditionsa7d5Bq_seoxYTy6jVGBqBNU1QQD4QGhV8PKDpmG0Sg4Meta } from "/codebuild/output/src1753048302/src/storefront/pages/terms-and-conditions.vue?macro=true";
import { default as _91slug_93CchCDnOaeqJbMWzcldwYQpPZ0Y0kpH8cuHuvZWSGgrwMeta } from "/codebuild/output/src1753048302/src/storefront/pages/other-language/[slug].vue?macro=true";
import { default as indexuPZZ6A4ziDis_45w9x9l9adZIUagMPipGERAv2ACmst4sMeta } from "/codebuild/output/src1753048302/src/storefront/pages/other-languages/index.vue?macro=true";
import { default as staff_45recommendationswe1lxy8UpkG53PwEubbfT98od1x27FCP_upgpCwLPBwMeta } from "/codebuild/output/src1753048302/src/storefront/pages/staff-recommendations.vue?macro=true";
import { default as _91slug_93ihgeI_4540lw8FSroC2z4Y_s0Y8KPer00OjjNKGyFwqSAMeta } from "/codebuild/output/src1753048302/src/storefront/pages/books-by-author/[slug].vue?macro=true";
import { default as index6c9jbyLz2CRVPOUaGTLVlLW93F5ew5r1rJu5Wk5lf5kMeta } from "/codebuild/output/src1753048302/src/storefront/pages/books/[category]/index.vue?macro=true";
import { default as _91slug_93L7tS47N4W_45_zXWX4n3yG005XMiqmRGCi7P3vxo8XldMMeta } from "/codebuild/output/src1753048302/src/storefront/pages/menu-navigation/[slug].vue?macro=true";
import { default as bestsellers_45of_45the_45monthTwFkxnJ79zIDjMIdwT8YSoXBi9KBn_8iQ_45TxqHT0GOIMeta } from "/codebuild/output/src1753048302/src/storefront/pages/bestsellers-of-the-month.vue?macro=true";
import { default as _91slug_93bgI8gM5pk7dv4lYXLvnDGFbKM1inV0HGIpmM_a2IuvUMeta } from "/codebuild/output/src1753048302/src/storefront/pages/stationary-and-gift/[slug].vue?macro=true";
import { default as indexgppCWEEfXGPOFkHkO0z2vU2OKd2FofrRiCPhBm3F8eQMeta } from "/codebuild/output/src1753048302/src/storefront/pages/stationary-and-gifts/index.vue?macro=true";
import { default as indexnypcnyLgKpEllEnsdbny_45H47xmhUxKCzK_45rN9CaD8dkMeta } from "/codebuild/output/src1753048302/src/storefront/pages/other-languages/[category]/index.vue?macro=true";
import { default as purchase_45and_45shipping_45conditions6HvmZqjNDdbDEnmS3Qwut7SEkQtU7lUt7PlS8WyP3noMeta } from "/codebuild/output/src1753048302/src/storefront/pages/purchase-and-shipping-conditions.vue?macro=true";
import { default as indexT7bBpR_45cdM_lcGYkW4apQuKwAE7V8qO5aTqcqcq5UDYMeta } from "/codebuild/output/src1753048302/src/storefront/pages/books/[category]/[subcategory]/index.vue?macro=true";
import { default as _91_91subcategory_93_93tA1fY0IkgwKhSq_45d1a5CvbuKiQk6rXoa3qqAJaD49AQMeta } from "/codebuild/output/src1753048302/src/storefront/pages/products/[[category]]/[[subcategory]].vue?macro=true";
import { default as indexELexhy7VuZKKqNyhgiDNBYnmarmw55RFwmv54CGs64YMeta } from "/codebuild/output/src1753048302/src/storefront/pages/stationary-and-gifts/[category]/index.vue?macro=true";
import { default as indexLnU6KUvIR1iJsEIYqV_7T22_ouvT_xMUMw1viAK_CEoMeta } from "/codebuild/output/src1753048302/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue?macro=true";
import { default as indexZmrEYKHVElJ_M5PjK0K_En5M8AGLjK3_aE9ubBUxtgwMeta } from "/codebuild/output/src1753048302/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue?macro=true";
export default [
  {
    name: "index___hu",
    path: "/hu",
    meta: indexxFIO8WrqDzYxzmdVSY6oauUkc9jstFMxDjHx8wrlsZUMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexxFIO8WrqDzYxzmdVSY6oauUkc9jstFMxDjHx8wrlsZUMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/index.vue")
  },
  {
    name: "login___hu",
    path: "/hu/bejelentkezes",
    meta: loginfRUKSfwpVRzr12wHl5KMD99TEXR_458eS4EiKYm25QnDYMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginfRUKSfwpVRzr12wHl5KMD99TEXR_458eS4EiKYm25QnDYMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/login.vue")
  },
  {
    name: "cookie___hu",
    path: "/hu/suti",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/cookie.vue")
  },
  {
    name: "cookie___en",
    path: "/cookie",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/cookie.vue")
  },
  {
    name: "search___hu",
    path: "/hu/kereses",
    meta: searchYqm3rQbJgQHwzLtL_45qwnSJvQjs3MQy8HCJYO_45lLlKNoMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/search",
    meta: searchYqm3rQbJgQHwzLtL_45qwnSJvQjs3MQy8HCJYO_45lLlKNoMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/search.vue")
  },
  {
    name: "my-cart___hu",
    path: "/hu/kosaram",
    meta: my_45cartAeIUQSBmbBKub4haVtkbVOIf5ols_45jkne3mdr_45N_45vmwMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/my-cart.vue")
  },
  {
    name: "my-cart___en",
    path: "/my-cart",
    meta: my_45cartAeIUQSBmbBKub4haVtkbVOIf5ols_45jkne3mdr_45N_45vmwMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/my-cart.vue")
  },
  {
    name: "on-sale___hu",
    path: "/hu/akciok",
    meta: on_45saleUijq11pp7iLAtEUmTE8WZ28g7QY6IxhIViGb3MzmZTMMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/on-sale.vue")
  },
  {
    name: "on-sale___en",
    path: "/on-sale",
    meta: on_45saleUijq11pp7iLAtEUmTE8WZ28g7QY6IxhIViGb3MzmZTMMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/on-sale.vue")
  },
  {
    name: "about-us___hu",
    path: "/hu/rolunk",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/about-us.vue")
  },
  {
    name: "about-us___en",
    path: "/about-us",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/about-us.vue")
  },
  {
    name: "book-club___hu",
    path: "/hu/konyvklub",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/book-club.vue")
  },
  {
    name: "book-club___en",
    path: "/book-club",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/book-club.vue")
  },
  {
    name: "contact-us___hu",
    path: "/hu/kapcsolat",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/contact-us.vue")
  },
  {
    name: "contact-us___en",
    path: "/contact-us",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/contact-us.vue")
  },
  {
    name: "newspapers___hu",
    path: "/hu/ujsagok-es-magazinok",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/newspapers.vue")
  },
  {
    name: "newspapers___en",
    path: "/newspapers-and-magazines",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/newspapers.vue")
  },
  {
    name: "book-slug___hu",
    path: "/hu/konyv/:slug()",
    meta: _91slug_93EhScuFPBIFOiFCU_FYmdXx2X_45iBoFGvLXHn2ykPARG0Meta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/book/[slug].vue")
  },
  {
    name: "book-slug___en",
    path: "/book/:slug()",
    meta: _91slug_93EhScuFPBIFOiFCU_FYmdXx2X_45iBoFGvLXHn2ykPARG0Meta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/book/[slug].vue")
  },
  {
    name: "books___hu",
    path: "/hu/konyvek",
    meta: indexDGpGBe5P2tg51O93g0o8S_6OKP2vUOMqufA5U2BiPnkMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/books/index.vue")
  },
  {
    name: "books___en",
    path: "/books",
    meta: indexDGpGBe5P2tg51O93g0o8S_6OKP2vUOMqufA5U2BiPnkMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/books/index.vue")
  },
  {
    name: "barion-start___hu",
    path: "/hu/barion-fizetes",
    meta: barion_45start3Xedf_45X9Krrs38t3NKQDJPq6XyTYffBPil8bYx5KCvMMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/barion-start.vue")
  },
  {
    name: "barion-start___en",
    path: "/barion-start",
    meta: barion_45start3Xedf_45X9Krrs38t3NKQDJPq6XyTYffBPil8bYx5KCvMMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/barion-start.vue")
  },
  {
    name: "paypal-start___hu",
    path: "/hu/paypal-fizetes",
    meta: paypal_45startv9xcHFUyksvfKOVdoVt3_45VDfCw5C21AqwHeONrTPhbwMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/paypal-start.vue")
  },
  {
    name: "paypal-start___en",
    path: "/paypal-start",
    meta: paypal_45startv9xcHFUyksvfKOVdoVt3_45VDfCw5C21AqwHeONrTPhbwMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/paypal-start.vue")
  },
  {
    name: "catchAll___hu",
    path: "/hu/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93qkJIqgNPrpX8Zvss1QfqIhesCk6mA5OeD9qjFcKfhoAMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/[...catchAll].vue")
  },
  {
    name: "catchAll___en",
    path: "/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93qkJIqgNPrpX8Zvss1QfqIhesCk6mA5OeD9qjFcKfhoAMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/[...catchAll].vue")
  },
  {
    name: "checkout___hu",
    path: "/hu/megrendeles",
    meta: indexTIC8vvB8wG_45y2bVksPzQb_45IRlalEP_45Twul_ujdLILZUMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/checkout/index.vue")
  },
  {
    name: "checkout___en",
    path: "/checkout",
    meta: indexTIC8vvB8wG_45y2bVksPzQb_45IRlalEP_45Twul_ujdLILZUMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/checkout/index.vue")
  },
  {
    name: "payment-result___hu",
    path: "/hu/barion-eredmeny",
    meta: payment_45resultSJHRm9aNVuTnLy_45XjDixYXuOsHB9saV3JDseGX_45TX88Meta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/payment-result.vue")
  },
  {
    name: "payment-result___en",
    path: "/barion-result",
    meta: payment_45resultSJHRm9aNVuTnLy_45XjDixYXuOsHB9saV3JDseGX_45TX88Meta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/payment-result.vue")
  },
  {
    name: "privacy-policy___hu",
    path: "/hu/adatkezelesi-tajekoztato",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/privacy-policy.vue")
  },
  {
    name: "my-orders___hu",
    path: "/hu/rendeleseim",
    meta: indexM9Na2t96_6nDNQiL1Zfz_45_3676r4OGzycfeHMl6HgBMMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/my-orders/index.vue")
  },
  {
    name: "my-orders___en",
    path: "/my-orders",
    meta: indexM9Na2t96_6nDNQiL1Zfz_45_3676r4OGzycfeHMl6HgBMMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/my-orders/index.vue")
  },
  {
    name: "newly-published___hu",
    path: "/hu/ujdonsagok",
    meta: newly_45publishedC7dvKtlqL8N_KjQvkpXlUuxjBvVaDBDPKhgGahm8VVQMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/newly-published.vue")
  },
  {
    name: "newly-published___en",
    path: "/newly-published",
    meta: newly_45publishedC7dvKtlqL8N_KjQvkpXlUuxjBvVaDBDPKhgGahm8VVQMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/newly-published.vue")
  },
  {
    name: "my-orders-slug___hu",
    path: "/hu/rendeleseim/:slug()",
    meta: _91slug_93FtFigMQH7JucTN92f8OIyNTvNBLlWvcS_45aaXg19GfGEMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/my-orders/[slug].vue")
  },
  {
    name: "my-orders-slug___en",
    path: "/my-orders/:slug()",
    meta: _91slug_93FtFigMQH7JucTN92f8OIyNTvNBLlWvcS_45aaXg19GfGEMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/my-orders/[slug].vue")
  },
  {
    name: "checkout-thankyou___hu",
    path: "/hu/megrendeles-koszonjuk",
    meta: thankyouuvN0uL8W1XrI3trcgTGSX178nc7qGyvxlBzlO9wMA_QMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/checkout/thankyou.vue")
  },
  {
    name: "checkout-thankyou___en",
    path: "/checkout-thankyou",
    meta: thankyouuvN0uL8W1XrI3trcgTGSX178nc7qGyvxlBzlO9wMA_QMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/checkout/thankyou.vue")
  },
  {
    name: "upcoming-releases___hu",
    path: "/hu/varhato-megjelenesek",
    meta: upcoming_45releasessjuxHoNjOFtERtl045ClT1jAh8zM_45jeXGWA_45CA3dJ9cMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/upcoming-releases.vue")
  },
  {
    name: "upcoming-releases___en",
    path: "/upcoming-releases",
    meta: upcoming_45releasessjuxHoNjOFtERtl045ClT1jAh8zM_45jeXGWA_45CA3dJ9cMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/upcoming-releases.vue")
  },
  {
    name: "terms-and-conditions___hu",
    path: "/hu/altalanos-szerzodesi-feltetelek",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/terms-and-conditions",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/terms-and-conditions.vue")
  },
  {
    name: "other-language-slug___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyv/:slug()",
    meta: _91slug_93CchCDnOaeqJbMWzcldwYQpPZ0Y0kpH8cuHuvZWSGgrwMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/other-language/[slug].vue")
  },
  {
    name: "other-language-slug___en",
    path: "/other-language/:slug()",
    meta: _91slug_93CchCDnOaeqJbMWzcldwYQpPZ0Y0kpH8cuHuvZWSGgrwMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/other-language/[slug].vue")
  },
  {
    name: "other-languages___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek",
    meta: indexuPZZ6A4ziDis_45w9x9l9adZIUagMPipGERAv2ACmst4sMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/other-languages/index.vue")
  },
  {
    name: "other-languages___en",
    path: "/other-languages",
    meta: indexuPZZ6A4ziDis_45w9x9l9adZIUagMPipGERAv2ACmst4sMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/other-languages/index.vue")
  },
  {
    name: "staff-recommendations___hu",
    path: "/hu/mi-ajanljuk",
    meta: staff_45recommendationswe1lxy8UpkG53PwEubbfT98od1x27FCP_upgpCwLPBwMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/staff-recommendations.vue")
  },
  {
    name: "staff-recommendations___en",
    path: "/staff-recommendations",
    meta: staff_45recommendationswe1lxy8UpkG53PwEubbfT98od1x27FCP_upgpCwLPBwMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/staff-recommendations.vue")
  },
  {
    name: "books-by-author-slug___hu",
    path: "/hu/szerzo-muvei/:slug()",
    meta: _91slug_93ihgeI_4540lw8FSroC2z4Y_s0Y8KPer00OjjNKGyFwqSAMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/books-by-author/[slug].vue")
  },
  {
    name: "books-by-author-slug___en",
    path: "/books-by-author/:slug()",
    meta: _91slug_93ihgeI_4540lw8FSroC2z4Y_s0Y8KPer00OjjNKGyFwqSAMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/books-by-author/[slug].vue")
  },
  {
    name: "books-category___hu",
    path: "/hu/konyvek/:category()",
    meta: index6c9jbyLz2CRVPOUaGTLVlLW93F5ew5r1rJu5Wk5lf5kMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/books/[category]/index.vue")
  },
  {
    name: "books-category___en",
    path: "/books/:category()",
    meta: index6c9jbyLz2CRVPOUaGTLVlLW93F5ew5r1rJu5Wk5lf5kMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/books/[category]/index.vue")
  },
  {
    name: "menu-navigation-slug___hu",
    path: "/hu/menu-navigacio/:slug()",
    meta: _91slug_93L7tS47N4W_45_zXWX4n3yG005XMiqmRGCi7P3vxo8XldMMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/menu-navigation/[slug].vue")
  },
  {
    name: "menu-navigation-slug___en",
    path: "/menu-navigation/:slug()",
    meta: _91slug_93L7tS47N4W_45_zXWX4n3yG005XMiqmRGCi7P3vxo8XldMMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/menu-navigation/[slug].vue")
  },
  {
    name: "bestsellers-of-the-month___hu",
    path: "/hu/a-honap-sikerkonyvei",
    meta: bestsellers_45of_45the_45monthTwFkxnJ79zIDjMIdwT8YSoXBi9KBn_8iQ_45TxqHT0GOIMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/bestsellers-of-the-month.vue")
  },
  {
    name: "bestsellers-of-the-month___en",
    path: "/bestsellers-of-the-month",
    meta: bestsellers_45of_45the_45monthTwFkxnJ79zIDjMIdwT8YSoXBi9KBn_8iQ_45TxqHT0GOIMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/bestsellers-of-the-month.vue")
  },
  {
    name: "stationary-and-gift-slug___hu",
    path: "/hu/notesz-es-ajandektargy/:slug()",
    meta: _91slug_93bgI8gM5pk7dv4lYXLvnDGFbKM1inV0HGIpmM_a2IuvUMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/stationary-and-gift/[slug].vue")
  },
  {
    name: "stationary-and-gift-slug___en",
    path: "/stationary-and-gift/:slug()",
    meta: _91slug_93bgI8gM5pk7dv4lYXLvnDGFbKM1inV0HGIpmM_a2IuvUMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/stationary-and-gift/[slug].vue")
  },
  {
    name: "stationary-and-gifts___hu",
    path: "/hu/noteszek-es-ajandektargyak",
    meta: indexgppCWEEfXGPOFkHkO0z2vU2OKd2FofrRiCPhBm3F8eQMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/stationary-and-gifts/index.vue")
  },
  {
    name: "stationary-and-gifts___en",
    path: "/stationary-and-gifts",
    meta: indexgppCWEEfXGPOFkHkO0z2vU2OKd2FofrRiCPhBm3F8eQMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/stationary-and-gifts/index.vue")
  },
  {
    name: "other-languages-category___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek/:category()",
    meta: indexnypcnyLgKpEllEnsdbny_45H47xmhUxKCzK_45rN9CaD8dkMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/other-languages/[category]/index.vue")
  },
  {
    name: "other-languages-category___en",
    path: "/other-languages/:category()",
    meta: indexnypcnyLgKpEllEnsdbny_45H47xmhUxKCzK_45rN9CaD8dkMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/other-languages/[category]/index.vue")
  },
  {
    name: "purchase-and-shipping-conditions___hu",
    path: "/hu/vasarlasi-es-szallitasi-feltetelek",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/purchase-and-shipping-conditions.vue")
  },
  {
    name: "purchase-and-shipping-conditions___en",
    path: "/purchase-and-shipping-conditions",
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/purchase-and-shipping-conditions.vue")
  },
  {
    name: "books-category-subcategory___hu",
    path: "/hu/konyvek/:category()/:subcategory()",
    meta: indexT7bBpR_45cdM_lcGYkW4apQuKwAE7V8qO5aTqcqcq5UDYMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/books/[category]/[subcategory]/index.vue")
  },
  {
    name: "books-category-subcategory___en",
    path: "/books/:category()/:subcategory()",
    meta: indexT7bBpR_45cdM_lcGYkW4apQuKwAE7V8qO5aTqcqcq5UDYMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/books/[category]/[subcategory]/index.vue")
  },
  {
    name: "products-category-subcategory___hu",
    path: "/hu/product/:category?/:subcategory?",
    meta: _91_91subcategory_93_93tA1fY0IkgwKhSq_45d1a5CvbuKiQk6rXoa3qqAJaD49AQMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/products/[[category]]/[[subcategory]].vue")
  },
  {
    name: "products-category-subcategory___en",
    path: "/products/:category?/:subcategory?",
    meta: _91_91subcategory_93_93tA1fY0IkgwKhSq_45d1a5CvbuKiQk6rXoa3qqAJaD49AQMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/products/[[category]]/[[subcategory]].vue")
  },
  {
    name: "stationary-and-gifts-category___hu",
    path: "/hu/noteszek-es-ajandektargyak/:category()",
    meta: indexELexhy7VuZKKqNyhgiDNBYnmarmw55RFwmv54CGs64YMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/stationary-and-gifts/[category]/index.vue")
  },
  {
    name: "stationary-and-gifts-category___en",
    path: "/stationary-and-gifts/:category()",
    meta: indexELexhy7VuZKKqNyhgiDNBYnmarmw55RFwmv54CGs64YMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/stationary-and-gifts/[category]/index.vue")
  },
  {
    name: "other-languages-category-subcategory___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek/:categorysubcategory?",
    meta: indexLnU6KUvIR1iJsEIYqV_7T22_ouvT_xMUMw1viAK_CEoMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue")
  },
  {
    name: "other-languages-category-subcategory___en",
    path: "/other-languages/:category()/:subcategory()",
    meta: indexLnU6KUvIR1iJsEIYqV_7T22_ouvT_xMUMw1viAK_CEoMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue")
  },
  {
    name: "stationary-and-gifts-category-subcategory___hu",
    path: "/hu/noteszek-es-ajandektargyak/:categorysubcategory?",
    meta: indexZmrEYKHVElJ_M5PjK0K_En5M8AGLjK3_aE9ubBUxtgwMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue")
  },
  {
    name: "stationary-and-gifts-category-subcategory___en",
    path: "/stationary-and-gifts/:category()/:subcategory()",
    meta: indexZmrEYKHVElJ_M5PjK0K_En5M8AGLjK3_aE9ubBUxtgwMeta || {},
    component: () => import("/codebuild/output/src1753048302/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue")
  }
]